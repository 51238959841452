/* eslint-disable react/display-name */
import camCapLeft from 'assets/images/ear-photo-left@3x.png';
import camCapRight from 'assets/images/ear-photo-right@3x.png';
import leftTopBorder from 'assets/images/leftTopBorder.png';
import rsCam from 'assets/images/radio-selected-camera.png';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { History } from 'history';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { confirmAlert, CustomUiOptions } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Link } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import {
  EarImageDetails,
  EarType,
  ImageFormat
} from 'services/detectionService/detectionCommand/detectionCommand';
import detectionService from 'services/detectionService/detectionService';
import {
  resetLoaderContent,
  setLoaderContent
} from 'shared/store/appStore/appActions/appActions';
import { AppContext } from 'shared/store/appStore/AppContext/AppContext';
import 'styles/TakePhoto.scss';
import CamErrorOverlay from '../components/CamErrorOverlay';
import {
  gridPointDetect,
  resetState,
  resetStateFirst,
  resetStateFirstTry,
  resetStateSecond,
  resetStateSecondTry
} from '../store/fittingActions/fittingActions';
import FittingContext from '../store/FittingContext/FittingContext';
import styles from './TakePhoto.module.scss';

// import { leftData, rightData } from './eardata';

type TakePhotoProps = {
  history: History;
};

const getMatchingErrors = (message?: string): string[] =>
  message ? message.split('.') : ['', ''];

const TakePhoto: React.FC<TakePhotoProps> = (props: TakePhotoProps) => {
  const { history } = props;
  const {
    dispatch,
    store: {
      firstChecked,
      firstPointStatus,
      secondPointStatus,
      leftMatchStatus,
      rightMatchStatus,
      firstImgData,
      secondImgData,
      firstGridStatus,
      secondGridStatus,
      currentOPEar,
      firstEarType,
      firstPointDetectResponse,
      firstMultiPointStatus,
      secondMultiPointStatus,
      firstMultiPointDetectResponse,
      secondMultiPointDetectResponse,
      firstGridDetectResponse,
      secondEarType,
      cardNumber,
      secondPointDetectResponse,
      secondGridDetectResponse,
      accessCodeCart
    }
  } = useContext(FittingContext);
  const { /* jwtData: userDetails, */ dispatch: appStateDispatch } = useContext(
    AppContext
  );
  const componentIsMounted = useRef(true);
  const [caminstDisplay, setCaminstDisplay] = useState(false);
  const [gridDetectionError, setGridDetectionError] = useState('');
  const [cameraError, setCameraError] = useState<null | Error>(null);
  const [showCamError, setShowCamError] = useState(false);

  const height = window.innerHeight;

  const showOverlay = caminstDisplay && !cameraError;
  // const adjHeight = width < height ? width * 1.5 : 'auto';
  // const width = window.innerWidth;

  const qrOverlayStyle = {
    borderBottomWidth: `${height * 0.05}px`,
    borderTopWidth: `${height * 0.08}px`,
    borderLeftWidth: `${height * 0.03}px`,
    borderRightWidth: `${height * 0.03}px`
  };
  const qrbrdrLeftTop = {
    left: `${height * 0.03}px`,
    top: `${height * 0.08}px`
  };
  const qrbrdrRightTop = {
    right: `${height * 0.03}px`,
    top: `${height * 0.08}px`
  };
  const qrbrdrLeftBottom = {
    left: `${height * 0.03}px`,
    bottom: `${height * 0.05}px`
  };
  const qrbrdrRightBottom = {
    right: `${height * 0.03}px`,
    bottom: `${height * 0.05}px`
  };
  const qrbrdrImg = { width: `${height * 0.15}px` };
  const earTypeHeadStyle = {
    paddingTop: `${(height * 0.06 - 20) / 2}px`,
    paddingBottom: `${(height * 0.06 - 20) / 2}px`
  };
  const earTypeHeadSpanStyle = { right: `${height * 0.03}px` };
  const cameraOverlayInstStyle = {
    display: showOverlay ? 'block' : 'none',
    bottom: `${height * 0.05 + 100}px`
  };
  const coInstArrdStyle = {
    display: showOverlay ? 'block' : 'none',
    bottom: `${height * 0.05 + 100 - 13}px`
  };

  useLayoutEffect(() => {
    /* disable pinch zoom on camera UI */
    const element = document.querySelector('meta[name="viewport"]');
    if (element) {
      element.setAttribute(
        'content',
        'width=device-width, initial-scale=1, user-scalable=no'
      );
    }
    return () => {
      if (element) {
        element.setAttribute('content', 'width=device-width, initial-scale=1');
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    disableBrowserBackButton();
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (componentIsMounted) {
      if (accessCodeCart.length <= 0) {
        history.push('/customfit');
      }

      if (firstPointStatus && secondPointStatus) {
        if (!leftMatchStatus && !rightMatchStatus) {
          dispatch(resetState()); /* resetPropState(); */
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (componentIsMounted) {
      const [firstPointErrorTitle, firstPointErrorBody] = getMatchingErrors(
        firstPointDetectResponse.message
      );

      const [
        firstMultiPointErrorTitle,
        firstMultiPointErrorBody
      ] = getMatchingErrors(firstMultiPointDetectResponse.message);

      const [firstGridErrorTitle, firstGridErrorBody] = getMatchingErrors(
        firstGridDetectResponse.message
      );

      const [secondPointErrorTitle, secondPointErrorBody] = getMatchingErrors(
        secondPointDetectResponse.message
      );

      const [
        secondMultiPointErrorTitle,
        secondMultiPointErrorBody
      ] = getMatchingErrors(secondMultiPointDetectResponse.message);
      const [secondGridErrorTitle, secondGridErrorBody] = getMatchingErrors(
        secondGridDetectResponse.message
      );

      if (gridDetectionError) {
        confirmAlert({
          customUI: ({ onClose }: CustomUiOptions) => (
            <div className="custom-ui takePhotoErrPP">
              <div className={`react-confirm-alert-body ${styles.alertBody}`}>
                <h1 className={styles.errorTitle}>
                  Error, Please try after sometime
                </h1>
                <div className="wrngEarDivCam">
                  Error while processing the request, Please try after sometime.
                </div>
              </div>
              <div className="text-center">
                <Button
                  className="btn snugsTopBtn"
                  onClick={(): void => {
                    onClose();
                    history.push('/customfit');
                    // window.location.href = '/';
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          ),
          closeOnClickOutside: false
        });
      }

      if (
        currentOPEar === 'First' ||
        (currentOPEar === 'FIRSTTRY' && firstImgData.length > 0)
      ) {
        const detEarType = firstEarType;
        if (firstGridStatus) {
          if (firstPointStatus && firstMultiPointStatus) {
            if (detEarType.toLowerCase() === 'left') {
              // First Ear Success Popup
              confirmAlert({
                customUI: ({ onClose }: CustomUiOptions) => {
                  setTimeout(() => {
                    onClose();
                    history.push('/customfit');
                  }, 2000);
                  return (
                    <div className="custom-ui">
                      <div
                        className={`react-confirm-alert-body ${styles.alertBody}`}
                      >
                        <h1>PERFECT</h1>
                        That’s a great ear photo
                      </div>
                      <div className="IconHeadConfirm text-center">
                        <span className="fa-layers fa-fw fa-5x">
                          <img alt="" src={rsCam} />
                        </span>
                      </div>
                    </div>
                  );
                },
                closeOnClickOutside: false
              });
            } else {
              // First Ear Wrong Ear Error Popup
              confirmAlert({
                customUI: ({ onClose }: CustomUiOptions) => (
                  <div className="custom-ui takePhotoErrPP">
                    <div
                      className={`react-confirm-alert-body ${styles.alertBody}`}
                    >
                      <h1 className={styles.errorTitle}>
                        That looks like the wrong ear
                      </h1>
                      <div className="wrngEarDivCam">
                        We were expecting the left ear for this photo. Please
                        put the fitting card on the correct ear.
                      </div>
                      <div className="reviewInstrsDivCam">
                        <Button
                          className="btn reviewInstrsBtnCam"
                          onClick={() => {
                            onClose();
                            dispatch(resetStateFirst());
                            history.push('/instructions');
                          }}
                        >
                          REVIEW Instructions
                        </Button>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        className="btn snugsTopBtn"
                        onClick={() => {
                          onClose();
                          dispatch(resetStateFirstTry());
                        }}
                      >
                        TRY AGAIN
                      </Button>
                    </div>
                  </div>
                ),
                closeOnClickOutside: false
              });
            }
          } else {
            confirmAlert({
              customUI: ({ onClose }: CustomUiOptions) => (
                <div className="custom-ui takePhotoErrPP">
                  <div
                    className={`react-confirm-alert-body ${styles.alertBody}`}
                  >
                    <div className="outerRedHeader">
                      WE DETECTED AN ISSUE.
                      <br />
                      PLEASE TRY AGAIN.
                      <span className="errorLineSpan" />
                    </div>
                    <h1 className={styles.errorTitle}>
                      {!firstPointStatus && firstPointErrorTitle}
                      {!firstMultiPointStatus && firstMultiPointErrorTitle}
                    </h1>
                    <div className="wrngEarDivCam">
                      {!firstPointStatus && firstPointErrorBody}
                      {!firstMultiPointStatus && firstMultiPointErrorBody}
                    </div>
                    <div className="reviewInstrsDivCam">
                      <Button
                        className="btn reviewInstrsBtnCam"
                        onClick={() => {
                          onClose();
                          dispatch(resetStateFirst());
                          history.push('/instructions');
                        }}
                      >
                        REVIEW Instructions
                      </Button>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      className="btn snugsTopBtn"
                      onClick={() => {
                        dispatch(resetStateFirstTry());
                        onClose();
                      }}
                    >
                      TRY AGAIN
                    </Button>
                  </div>
                </div>
              ),
              closeOnClickOutside: false
            });
          }
        } else {
          confirmAlert({
            customUI: ({ onClose }: CustomUiOptions) => (
              <div className="custom-ui takePhotoErrPP">
                <div className={`react-confirm-alert-body ${styles.alertBody}`}>
                  <div className="outerRedHeader">
                    WE DETECTED AN ISSUE.
                    <br />
                    PLEASE TRY AGAIN.
                    <span className="errorLineSpan" />
                  </div>
                  {/* <h1>That photo didn’t work</h1> */}
                  <h1 className={styles.errorTitle}>{firstGridErrorTitle}</h1>
                  <div className="wrngEarDivCam">{firstGridErrorBody}</div>
                  <div className="reviewInstrsDivCam">
                    <Button
                      className="btn reviewInstrsBtnCam"
                      onClick={() => {
                        onClose();
                        dispatch(resetStateFirst());
                        history.push('/instructions');
                      }}
                    >
                      REVIEW Instructions
                    </Button>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    className="btn snugsTopBtn"
                    onClick={() => {
                      dispatch(resetStateFirstTry());
                      onClose();
                    }}
                  >
                    TRY AGAIN
                  </Button>
                </div>
              </div>
            ),
            closeOnClickOutside: false
          });
        }
      } else if (
        currentOPEar === 'Second' ||
        (currentOPEar === 'SECONDTRY' && secondImgData.length > 0)
      ) {
        const detEarType = secondEarType;
        if (secondGridStatus) {
          if (secondPointStatus) {
            if (detEarType.toLowerCase() === 'right') {
              confirmAlert({
                customUI: ({ onClose }: CustomUiOptions) => {
                  setTimeout(() => {
                    onClose();
                    history.push('/customfit');
                  }, 2000);
                  return (
                    <div className="custom-ui">
                      <div
                        className={`react-confirm-alert-body ${styles.alertBody}`}
                      >
                        <h1>PERFECT</h1>
                        That’s a great ear photo
                      </div>
                      <div className="IconHeadConfirm text-center">
                        <span className="fa-layers fa-fw fa-5x">
                          <img alt="" src={rsCam} />
                        </span>
                      </div>
                    </div>
                  );
                },
                closeOnClickOutside: false
              });
            } else {
              confirmAlert({
                customUI: ({ onClose }: CustomUiOptions) => (
                  <div className="custom-ui takePhotoErrPP">
                    <div
                      className={`react-confirm-alert-body ${styles.alertBody}`}
                    >
                      <h1 className={styles.errorTitle}>
                        That looks like the wrong ear
                      </h1>
                      <div className="wrngEarDivCam">
                        We were expecting the right ear for this photo. Please
                        put the fitting card on the correct ear.
                      </div>
                      <div className="reviewInstrsDivCam">
                        <Button
                          className="btn reviewInstrsBtnCam"
                          onClick={() => {
                            onClose();
                            dispatch(resetStateSecond());
                            history.push('/instructions');
                          }}
                        >
                          REVIEW Instructions
                        </Button>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        className="btn snugsTopBtn"
                        onClick={() => {
                          onClose();
                          dispatch(resetStateSecondTry());
                        }}
                      >
                        TRY AGAIN
                      </Button>
                    </div>
                  </div>
                ),
                closeOnClickOutside: false
              });
            }
          } else {
            confirmAlert({
              customUI: ({ onClose }: CustomUiOptions) => (
                <div className="custom-ui takePhotoErrPP">
                  <div
                    className={`react-confirm-alert-body ${styles.alertBody}`}
                  >
                    <div className="outerRedHeader">
                      WE DETECTED AN ISSUE.
                      <br />
                      PLEASE TRY AGAIN.
                      <span className="errorLineSpan" />
                    </div>
                    <h1 className={styles.errorTitle}>
                      {!secondPointStatus && secondPointErrorTitle}
                      {!secondMultiPointStatus && secondMultiPointErrorTitle}
                    </h1>
                    <div className="wrngEarDivCam">
                      {!secondPointStatus && secondPointErrorBody}
                      {!secondMultiPointStatus && secondMultiPointErrorBody}
                    </div>
                    <div className="reviewInstrsDivCam">
                      <Button
                        className="btn reviewInstrsBtnCam"
                        onClick={(): void => {
                          onClose();
                          dispatch(resetStateSecond());
                          history.push('/instructions');
                        }}
                      >
                        REVIEW Instructions
                      </Button>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      className="btn snugsTopBtn"
                      onClick={(): void => {
                        dispatch(resetStateSecondTry());
                        onClose();
                      }}
                    >
                      TRY AGAIN
                    </Button>
                  </div>
                </div>
              ),
              closeOnClickOutside: false
            });
          }
        } else {
          confirmAlert({
            customUI: ({ onClose }: CustomUiOptions) => (
              <div className="custom-ui takePhotoErrPP">
                <div className={`react-confirm-alert-body ${styles.alertBody}`}>
                  <div className="outerRedHeader">
                    WE DETECTED AN ISSUE.
                    <br />
                    PLEASE TRY AGAIN.
                    <span className="errorLineSpan" />
                  </div>
                  <h1 className={styles.errorTitle}>{secondGridErrorTitle}</h1>
                  <div className="wrngEarDivCam">{secondGridErrorBody}</div>
                  <div className="reviewInstrsDivCam">
                    <Button
                      className="btn reviewInstrsBtnCam"
                      onClick={(): void => {
                        onClose();
                        dispatch(resetStateSecond());
                        history.push('/instructions');
                      }}
                    >
                      REVIEW Instructions
                    </Button>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    className="btn snugsTopBtn"
                    onClick={(): void => {
                      dispatch(resetStateSecondTry());
                      onClose();
                    }}
                  >
                    TRY AGAIN
                  </Button>
                </div>
              </div>
            ),
            closeOnClickOutside: false
          });
        }
      }
    }
  });

  const checkGridDetection = async (
    imageData: EarImageDetails
  ): Promise<void> => {
    try {
      const gridDetectionResponse = await detectionService.validateGridDetection(
        imageData
      );
      if (gridDetectionResponse?.data.detect_status) {
        const imageProcessingResponse = await detectionService.performImageProcessing(
          {
            encoded_image: imageData.imgData
          }
        );

        if (imageProcessingResponse?.data.detect_status === 'success') {
          const pointDetectionResponse = await detectionService.validatePointDetection(
            {
              ...imageData,
              imgData: imageProcessingResponse.data.encoded_image
              // imgData:
              //   imageData.ear_type === EarType.First ? leftData : rightData
            }
          );

          const multiPointDetectionResponse = await detectionService.validateMultiPointDetection(
            {
              card_number: imageData.cardNumber,
              ear_type: imageData.ear_type,
              image_data: imageProcessingResponse.data.encoded_image
              // image_data:
              //   imageData.ear_type === EarType.First ? leftData : rightData
            }
          );

          dispatch(
            gridPointDetect(
              gridDetectionResponse.data,
              imageProcessingResponse.data,
              pointDetectionResponse.data,
              multiPointDetectionResponse.data,
              imageData
            )
          );
        } else {
          // point detection null
          dispatch(
            gridPointDetect(
              gridDetectionResponse.data,
              imageProcessingResponse.data,
              null,
              null,
              imageData
            )
          );
        }
      } else {
        // image processing & point detection null
        dispatch(
          gridPointDetect(
            gridDetectionResponse.data,
            null,
            null,
            null,
            imageData
          )
        );
      }
    } catch (error) {
      setGridDetectionError('Error while doing grid detection.');
    }
    appStateDispatch(resetLoaderContent());
  };

  const instDoneClick = (): void => {
    setCaminstDisplay(false);
  };

  const initiateCamera = (error?: Error): void => {
    if (error) {
      setCaminstDisplay(false);
      setCameraError(error);
      setShowCamError(true);
    } else {
      setCaminstDisplay(true);
      setCameraError(null);
      setShowCamError(false);
    }
  };

  const onTakePhoto = (dataUri: string): void => {
    // eslint-disable-next-line no-useless-escape
    const rawImageData = dataUri.replace(/^data\:image\/\w+\;base64\,/, '');

    const earType = firstChecked ? EarType.First : EarType.Second;
    const imageFormat = ImageFormat.JPEG;
    const uuid = /* userDetails.identity?.user_unique_id ?? */ 0; // TODO: temp remove

    const imageData = new EarImageDetails(
      uuid,
      rawImageData,
      imageFormat,
      earType,
      cardNumber
    );

    appStateDispatch(
      setLoaderContent({
        showLoader: true,
        loaderHeader: 'one moment please...',
        isCustom: false,
        loaderMessage: "We're checking your photo"
      })
    );
    // dispatch(showLoader("We're just checking your photo")); // Our AI is checking the photo to make sure it is accurate.
    instDoneClick();
    checkGridDetection(imageData);
  };

  return (
    <>
      <Col className="pl-0 pr-0 CameraCaptureOuter" style={{ height: '93vh' }}>
        {/* <CustomLoader
    isLoader={isLoader}
    background="#f1f1f1"
    heading="one moment please"
    content={loadMessage}
    spinner={cameraCaptureSpinner}
    isCustom={false}
  /> */}
        <Col
          className="text-uppercase EarTypeHead text-center"
          style={earTypeHeadStyle}
        >
          {firstChecked ? 'Left' : 'Right'} Ear
          <span className="EarTypeHeadSpan" style={earTypeHeadSpanStyle}>
            <Link to="/customfit">CANCEL</Link>
          </span>
        </Col>
        <div className="leftTop_cam borderDiv_cam" style={qrbrdrLeftTop}>
          <img style={qrbrdrImg} src={leftTopBorder} alt="" />
        </div>
        <div className="rightTop_cam borderDiv_cam" style={qrbrdrRightTop}>
          <img style={qrbrdrImg} src={leftTopBorder} alt="" />
        </div>
        <div className="leftBottom_cam borderDiv_cam" style={qrbrdrLeftBottom}>
          <img style={qrbrdrImg} src={leftTopBorder} alt="" />
        </div>
        <div
          className="rightBottom_cam borderDiv_cam"
          style={qrbrdrRightBottom}
        >
          <img style={qrbrdrImg} src={leftTopBorder} alt="" />
        </div>
        <div
          className="camera_overlay_InstructionDiv"
          style={cameraOverlayInstStyle}
        >
          <div className="camera_overlay_InstructionDiv_Image">
            {firstChecked ? (
              <img alt="" src={camCapLeft} />
            ) : (
              <img alt="" src={camCapRight} />
            )}
          </div>
          <div className="camera_overlay_InstructionDiv_Content">
            {`Place the fitting card over the ${firstChecked ? 'left' : 'right'}
            ear with grid facing the camera.`}
          </div>
          <div className="camera_overlay_InstructionDiv_Btn text-center">
            <Button
              className="btn cam_overlay_inst_btn"
              onClick={instDoneClick}
            >
              Done
            </Button>
          </div>
        </div>
        <div className="arrow-down co_inst_arrd" style={coInstArrdStyle} />
        <div className="camera_overlay_div" style={qrOverlayStyle} />
        <CamErrorOverlay
          onDismiss={() => setShowCamError(false)}
          show={showCamError}
          error={cameraError}
        />
        <Col className="pl-0 pr-0" id="PhotoFrameDiv">
          {/* issue here camera does not unmount on component unmount */}
          <Camera
            id="camera_video"
            onTakePhoto={onTakePhoto}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            imageType={IMAGE_TYPES.JPG}
            isMaxResolution={false}
            isImageMirror={false}
            isSilentMode
            onCameraStart={() => initiateCamera()}
            onCameraError={(error: Error) => initiateCamera(error)}
            isDisplayStartCameraError
            isFullscreen
          />
        </Col>
      </Col>
    </>
  );
};
export default TakePhoto;
